

export let baseurl = "https://safehaventb.com/eliteonlinebankbackend/engine.php";
//export let baseurl = "http://localhost/eliteonlinebankbackend/engine.php";

export function setCookie(cookieName, cookieValue, expirationMinutes) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationMinutes * 60 * 1000)); // Set the expiration time in minutes
    var expires = "expires=" + d.toUTCString(); // Convert the date to UTC string
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"; // Set the cookie with name, value, expiration, and path
}

export function checkCookie(cookieName) {
    // Split document.cookie string into individual cookies
    var cookies = document.cookie.split(';');
    
    // Iterate over each cookie
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim(); // Trim any leading or trailing whitespace
        // Check if the cookie starts with the provided cookieName
        if (cookie.indexOf(cookieName + '=') === 0) {
            return true; // Cookie exists
        }
    }
    return false; // Cookie does not exist
}

export function getCookie(cookieName) {
    
    const cookiesArray = document.cookie.split(';');
  
    // Iterate over each cookie to find the one with the provided name
    for (let cookie of cookiesArray) {
      // Remove leading spaces (if any)
      cookie = cookie.trim();
  
      // Check if the cookie starts with the provided name
      if (cookie.startsWith(cookieName + '=')) {
        // Return the value of the cookie
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    // Return null if the cookie doesn't exist
    return null;
  }
  

