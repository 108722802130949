import React, { useEffect, useState } from "react";
import "../styles/AdminDashboard.css";
import axios from "axios";
import { baseurl, setCookie, getCookie } from "../utils/constants";
function AdminDashboard() {
  const [searchValue, setSearchValue] = useState("");
  const [searchingUser, setSearcingUser]=useState(false)
  const [allusers, setAllusers] = useState([]);
  const [searchedusers, setSearchUsers] = useState([]);
  const [showsearchresults, setshowsearchresults] = useState(false);
  const [addingfunds, setAddingFunds] = useState(false);
  const [userInputs, setUserInputs] = useState([]);
  const [sendingOTP, setSendingOTP] = useState(false);
  const [verifyingAccounnt, setVerifyingAccount] = useState(false);
  const [linkingAccounnt, setLinkingAccount] = useState(false);
  const [deductingFunds, setDeductingFunds] = useState(false);
  const [disablingAccount, setDisablingAccount] = useState(false);
  const [sendingIMFCode, setSendingIMFCode] = useState(false);
  const [allUserTransactions, setAllusersTransactions] = useState(false);
  const [ approvingTransaction, setApprovingTransaction]= useState(false);
  const [cancelingTransactions, setCancelingTransactions] = useState(false)
  const [senderName, setSenderName] = useState("")

  const handleInputChange = (index, event) => {
    const values = [...userInputs];
    values[index] = event.target.value;
    setUserInputs(values);
  };

  // useEffect(() => {
  //   async function getAllUsers() {
  //     const requestData = {
  //       requestTask: "getallUserData",
  //     };

  //     let result = await axios.post(baseurl, requestData,{timeout: 35000});
  //     console.log(result.data);
  //     setAllusers(result.data);
  //   }

  //   async function getAllUsersTransactions() {
  //     const requestData = {
  //       requestTask: "getAllUsersTransactions",
  //     };

  //     let result = await axios.post(baseurl, requestData, {timeout: 35000});
  //     console.log("GET ALL USER TRANSACTIONS: ", result.data);
  //     setAllusersTransactions(result.data);
  //   }

  //   getAllUsers();
  //   getAllUsersTransactions();
  // }, []);

 async function handleSearch() {
    // let searchresult = allusers.filter((user) =>
    //   user.email.includes(searchValue)
    // );
    // setSearchUsers(searchresult);
    // setshowsearchresults(true);

    if (!searchValue){
      alert("Please insert a client email")
      return
    }

    



    

    const requestData = {
            requestTask: "searchUser",
            email:searchValue
          };
    
          setSearcingUser(true)
          let result = await axios.post(baseurl, requestData);
          console.log("SEACH RESULT: ", result.data);

          if (!result.data){
            alert("You have bad Network.'\n Please move to a place with better newtwork")
            setSearcingUser(false)
            return
          }
         if (result.data=="notfound"){
          alert("No client with this email found. Please check the email well.")
          setSearcingUser(false)
          return
         }
          if (Array.isArray(result.data)){
            setAllusers(result.data[0]);
            setAllusersTransactions(result.data[1])
          }
          else{
            setAllusers([]);
            setAllusersTransactions([])
          }
        setSearcingUser(false)
        //  setSearchUsers(result.data)
        console.log("AllUSers: ",allusers)
        console.log("AllUSers transaction: ",allUserTransactions)

  }
  function generateOTP() {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var OTP = "";
    for (var i = 0; i < 8; i++) {
      OTP += chars[Math.floor(Math.random() * chars.length)];
    }
    return OTP;
  }

  async function addFunds(email, amount, username, currency, accountNumber) {
    setAddingFunds(true);
    const requestData = {
      requestTask: "addfunds",
      email: email,
      amount: amount,
      firstName: username,
      currency,
      accountNumber,
      senderName: senderName
    };
    console.log(requestData);

    let result = await axios.post(baseurl, requestData);
    console.log("RESULT OF CREDIT", result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setAddingFunds(false);
      alert("User credited");
    } else {
      console.log(result.data);
      alert("An error occured");
      setAddingFunds(false);
    }
  }

  async function deductFunds(email, amount, username, currency, accountNumber) {
    setDeductingFunds(true);
    const requestData = {
      requestTask: "deductfunds",
      email: email,
      amount: amount,
      firstName: username,
      currency,
      accountNumber,
    };
    console.log(requestData);

    let result = await axios.post(baseurl, requestData);
    console.log("RESULT OF DEBIT", result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setDeductingFunds(false);
      alert("User debited");
    } else {
      console.log(result.data);
      alert("An error occured");
      setDeductingFunds(false);
    }
  }

  async function sendOTP(email, username) {
    setSendingOTP(true);
    let OTP = generateOTP();
    const requestData = {
      requestTask: "sendBillingOTP",
      email: email,
      firstName: username,
      OTP: OTP,
    };

    let result = await axios.post(baseurl, requestData);
    console.log(result.data);

    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      alert("OTP SENT");
      setSendingOTP(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setSendingOTP(false);
    }
  }

  async function verifyAccount(email, username) {
    setVerifyingAccount(true);
    const requestData = {
      requestTask: "verifyAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result);
    if (result.data !== "error" && Array.isArray(result.data)) {
      alert("USER VERIFIED!!!");
      setAllusers(result.data);
      setVerifyingAccount(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setVerifyingAccount(false);
    }
  }
  async function LinkAccount(email, username) {
    setLinkingAccount(true);
    const requestData = {
      requestTask: "linkAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      alert("ACCOUNT LINKED!!!");
      setAllusers(result.data);
      console.log(result.data);
      setLinkingAccount(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setLinkingAccount(false);
    }
  }

  async function disableAccount(user) {
    setDisablingAccount(true);
    let requestData = {
      requestTask: "disableaccount",
      email: user.email,
    };

    const result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data.trim() === "accountdisabled") {
      const requestData = {
        requestTask: "getallUserData",
      };

      let Users = await axios.post(baseurl, requestData);
      console.log(Users.data);
      if (Users && Array.isArray(Users.data)) {
        setAllusers(Users.data);
      }
      setDisablingAccount(false);
      alert("Account Disabled.");
    }
  }

  const [enablingAccount, setEnablingAccount]= useState(false)
  async function enableAccount(user) {
    setEnablingAccount(true);
    let requestData = {
      requestTask: "enableaccount",
      email: user.email,
    };

    const result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data.trim() === "accountenabled") {
      const requestData = {
        requestTask: "getallUserData",
      };

      let Users = await axios.post(baseurl, requestData);
      console.log(Users.data);
      if (Users && Array.isArray(Users.data)) {
        setAllusers(Users.data);
      }
      setEnablingAccount(false);
      alert("Account Enabled.");
    }
  }

  async function sendIMFIFSCCode(email, username) {
    setSendingIMFCode(true);
    const requestData = {
      requestTask: "sendIMFCode",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      alert("USER IMF/IFSC CODE SENT!!!");
      setAllusers(result.data);
      setSendingIMFCode(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setSendingIMFCode(false);
    }
  }

  async function approveTransaction(user, transaction) {
    let requestData = {
      requestTask: "approveWithdrawal",
      email: user.email,
      currency: user.currency,
      firstName: user.firstName,
      transactionID: transaction.id,
      amount: transaction.Amount,
      sendingChanel: transaction.sendingChanel,
      recievingEntity: transaction.recievingEntity,
      recivingentitypoint: transaction.recivingentitypoint,
    };

    console.log(requestData);
    setApprovingTransaction(true)
    axios.post(baseurl, requestData)
    .then((response)=>{
      console.log(response.data);
      setAllusersTransactions(response.data)
      setApprovingTransaction(false)
    })
    .catch((error)=>{
      console.log(error);
      alert("An error occured. Check your internet connecion.")
      setApprovingTransaction(false)
    })
   
  }


  async function cancelWithdrawal(user, transaction) {
    let requestData = {
      requestTask: "cancelWithdrawal",
      email: user.email,
      currency: user.currency,
      firstName: user.firstName,
      transactionID: transaction.id,
      amount: transaction.Amount,
      sendingChanel: transaction.sendingChanel,
      recievingEntity: transaction.recievingEntity,
      recivingentitypoint: transaction.recivingentitypoint,
    };

    console.log(requestData);
    setCancelingTransactions(true)
    axios.post(baseurl, requestData)
    .then((response)=>{
      console.log(response.data);
      setAllusersTransactions(response.data)
      setCancelingTransactions(false)
    })
    .catch((error)=>{
      console.log(error);
      alert("An error occured. Check your internet connecion.")
      setCancelingTransactions(false)
    })
   
  }

  const [sendingReminderEmail, setSendingReminderEmail]= useState(false)
   async function  sendNotification(user){

    let requestData = {
      requestTask:"sendReminder",
      email:user.email,
      firstName: user.firstName
    }

    console.log(requestData)
    setSendingReminderEmail(true);

    await axios.post(baseurl, requestData)
    .then((response)=>{
        console.log (response.data)
      if (response.data=="sent"){
        alert("Reminder Sent");
      }
      else{
        alert("Not sent")
      }
      setSendingReminderEmail(false)
    })
    .catch((error)=>{console.log(error)})


   }


  function UserContainer({ user, index, transactions }) {
    return (
      <div>
        <h3>{user.email}</h3>

        <div className="user-controls-buttons">
          <button
            style={{
              backgroundColor:
                user.AccountStatus === "ACTIVE" ? "blue" : "grey",
              color: "#fff",
              padding: "0.5rem 1rem",
              border: "none",
              borderRadius: "5px",
              cursor:
                user.AccountStatus === "ACTIVE" ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (user.AccountStatus === "ACTIVE") {
                if (window.confirm("Are you sure you want to perform this action?")){
                  disableAccount(user);
                }
               
              }
            }}
            disabled={user.AccountStatus !== "ACTIVE" || disablingAccount}
          >
            {disablingAccount ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Disable User"
            )}
          </button>

          <button
            style={{
              backgroundColor:
                user.AccountStatus === "NOTACTIVE" ? "blue" : "grey",
              color: "#fff",
              padding: "0.5rem 1rem",
              border: "none",
              borderRadius: "5px",
              cursor:
                user.AccountStatus === "ACTIVE" ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (user.AccountStatus === "NOTACTIVE") {
                if (window.confirm("Are you sure you want to perform this action?")){
                  enableAccount(user);
                }
               
              }
            }}
            disabled={user.AccountStatus !== "NOTACTIVE" || enablingAccount}
          >
            {enablingAccount ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Enable User"
            )}
          </button>
          <button
            className={user.OTP == "NULL" ? "" : "disabled-button"}
            onClick={() => {
              if (user.OTP == "NULL") {
                if (window.confirm("Are you sure you want to perform this action?")){
                  sendOTP(user.email, user.firstName);
                }
               
              }
            }}
          >
            {sendingOTP ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Send OTP"
            )}
          </button>

          <button
            className={
              user.AccountVerification == "NOT VERIFIED"
                ? ""
                : "disabled-button"
            }
            onClick={() => {
              if (user.AccountVerification == "NOT VERIFIED") {
                

                if (window.confirm("Are you sure you want to perform this action?")){
               verifyAccount(user.email, user.firstName);
                }
              }
            }}
          >
            {verifyingAccounnt ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Verify Account"
            )}
          </button>

          <button
            className={
              user.AccountLinking == "NOT LINKED" ? "" : "disabled-button"
            }
            onClick={() => {
              if (user.AccountLinking == "NOT LINKED") {
                if (window.confirm("Are you sure you want to perform this action?")){
                LinkAccount(user.email, user.firstName);
                }
               
              }
            }}
          >
            {linkingAccounnt ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Link Account"
            )}
          </button>

          <button
            className={user.AMLIMF == "NULL" ? "" : "disabled-button"}
            onClick={() => {
              if (user.AMLIMF == "NULL") {
                if (window.confirm("Are you sure you want to perform this action?")){
                 sendIMFIFSCCode(user.email, user.firstName);
                }
                
              }
            }}
          >
            {sendingIMFCode ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "SEND IMF/IFSC CODE"
            )}
          </button>
        </div>

        <button
          onClick={() => {
            
            if (window.confirm("Are you sure you want to perform this action?")){
             lockfunds(user);
            }
          }}
        >
          {lockingFunds ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Lock Funds"
          )}
        </button>

        <button
          onClick={() => {
            
            if (window.confirm("Are you sure you want to perform this action?")){
             unlockfunds(user);
            }
          }}
        >
          {lockingFunds ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "UnLock Funds"
          )}
        </button>

        <button onClick={()=>{
           if (window.confirm("Are you sure you want to perform this action?")){
            sendNotification(user)
           }
        }}>
       {sendingReminderEmail ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Send Reminder"
          )}
        </button>

        <div className="funds-container">
          <input
            placeholder="Amount"
            type="number"
            value={userInputs[index]}
            onChange={(event) => handleInputChange(index, event)}
          />
           <input
            placeholder="sender name"
            type="text"
            value={senderName}
            onChange={(event) => setSenderName(event.target.value)}
          />
          <div>
            <button
              onClick={() => {
                if (userInputs[index] > 0) {
                  if (senderName==""){
                       alert("Please add sender name")
                  }
                  else{
                    if (window.confirm("Are you sure you want to perform this action?")){
                      addFunds(
                        user.email,
                        userInputs[index],
                        user.firstName,
                        user.currency,
                        user.AccountNumber
                      );
                    }
                  }
                 
                } else {
                  alert("Oga add amount");
                }
              }}
            >
              {addingfunds ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Credit Account"
              )}
            </button>
            <button
              onClick={() => {
                if (userInputs[index] > 0) {
                 
                  if (window.confirm("Are you sure you want to perform this action?")){
                    deductFunds(
                      user.email,
                      userInputs[index],
                      user.firstName,
                      user.currency,
                      user.AccountNumber
                    );
  
                  }
                } else {
                  alert("Oga add amount");
                }
              }}
            >
              {deductingFunds ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Debit Account"
              )}
            </button>

            <div></div>
          </div>

          <div
            style={{
              backgroundColor: "gray",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <p>PENDING Withdawals</p>
            {allUserTransactions.map((transaction, transindex) => {
              if (
                transaction.email == user.email &&
                transaction.STATUS == "NOT COMPLETE"
              ) {
                return (
                  <div key={transindex}>
                    <p>Amount: {transaction.Amount}</p>
                    <p>
                      To:{transaction.recievingEntity}{" "}
                      {transaction.recivingentitypoint}
                    </p>
                    <p>Channel:{transaction.sendingChanel}</p>

                    <div>
                      <button
                        onClick={() => {
                          if (window.confirm("Are you sure you want to perform this action?")){
                            approveTransaction(user, transaction);
                          }
                          
                        }}
                      >
                       {approvingTransaction ?(<i class="fa-solid fa-spinner fa-spin"></i>):"Approve"}
                      </button>

                     
                      <button onClick={()=>{
                        if (window.confirm("Are you sure you want to perform this action?")){
                          cancelWithdrawal(user, transaction);
                        }
                      }}>{cancelingTransactions ?(<i class="fa-solid fa-spinner fa-spin"></i>):"Cancel"}</button>
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <details>
            <summary>More Details</summary>
            <div className="details-container">
              <p>
                <b>First Name: </b>
                {user.firstName}
              </p>
              <p>
                <b>Last Name: </b>
                {user.lastName}
              </p>
              <p>
                <b>Status: </b>
                {user.AccountStatus}
              </p>
              <p>
                <b>Account Number: </b>
                {user.AccountNumber}
              </p>
              <p>
                <b>Balance: </b>
                {user.Balance}
              </p>
              <p>
                <b>Currency: </b>
                {user.currency}
              </p>
              <p>
                <b>Address: </b>
                {user.useraddress}
              </p>
              <p>
                <b>Country: </b>
                {user.country}
              </p>

              <p>
                <b>OTP: </b>
                {user.OTP == "NULL" ? "Not Sent" : "Sent"}
              </p>
              <p>
                <b>OTP CODE: </b>
                {user.OTP}
              </p>
              <p>
                <b>Verification: </b>
                {user.AccountVerification}
              </p>
              <p>
                <b>Linking: </b>
                {user.AccountLinking}
              </p>
              <p>
                <b>AML/IMF: </b>
                {user.AMLIMF}
              </p>
            </div>
          </details>
        </div>
      </div>
    );
  }

  const [lockingFunds, setLockingFunds] = useState(false);
  function lockfunds(user) {
    let requestData = {
      requestTask: "lockfunds",
      email: user.email,
    };

    setLockingFunds(true);
    axios.post(baseurl, requestData).then(async(result) => {
      if (result.data === "updated") {
        const requestData = {
          requestTask: "getallUserData",
        };
  
        let Users = await axios.post(baseurl, requestData);
        console.log(Users.data);
        if (Users && Array.isArray(Users.data)) {
          setAllusers(Users.data);
          alert("User funds locked!")
        }
      } else {
        alert("An error occured.");
      }
      console.log(result);
    });
    setLockingFunds(false);
  }

  const [unlockingUser, setunLockingUser] = useState(false);
  function unlockfunds(user) {
    let requestData = {
      requestTask: "unlockfunds",
      email: user.email,
    };

    setunLockingUser(true);
    axios.post(baseurl, requestData).then(async(result) => {
      if (result.data === "updated") {
        const requestData = {
          requestTask: "getallUserData",
        };
  
        let Users = await axios.post(baseurl, requestData);
        console.log(Users.data);
        if (Users && Array.isArray(Users.data)) {
          setAllusers(Users.data);
          alert("User funds unlocked!")
        }
      } else {
        alert("An error occured.");
      }
      console.log(result);
    });
    setunLockingUser(false);
  }

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-wrapper">
      <h1 style={{fontSize:"32px", fontWeight:"bold", textAlign:"center"}}>Welcome to safe haven admin</h1>
      <p style={{textAlign:"center"}}>You can now search for one client at a time</p>
        <div className="search-div" style={{marginTop:"50px"}}>
         
        
          <input
            type="email"
            placeholder="search-email"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {showsearchresults ? (
            <i
              class="fa-solid fa-xmark search-button"
              onClick={() => {
                setSearchValue("");
                setshowsearchresults(false);
              }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-magnifying-glass search-button"
              onClick={() => {
                handleSearch();
              }}
            ></i>
          )}
       
        </div>



        {
            searchingUser &&
            <div style={{position:"relative", display:"flex", justifyItems:"center", justifyContent:"center"}}>
              <i class="fa-solid fa-xmark fa-spin-pulse" style={{fontSize:'100px', }}></i>
            </div>
          }
        {allusers.length > 0 ? (
          <div>

              <div className="user-container">
                {allusers.length > 0 ? (
                  allusers.map((user, index) => (
                    <div key={index}>
                      <UserContainer key={index} user={user} index={index} />
                    </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            
          </div>
        ) : (
          <div className="user-container" style={{color:"gray", textAlign:"center"}}>
            <p>No client searched</p>
          </div>
        )}
      </div>


    </div>
  );
}

export default AdminDashboard;
